import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
        social: { twitter: string }
      }
    }
  }
}

const NotFoundPage = ({ data }: Props) => {
  const {
    title: siteTitle,
    siteUrl,
    social: { twitter },
  } = data.site.siteMetadata

  return (
    <Layout title={siteTitle} twitter={twitter}>
      <SEO title="404: Not Found" url={siteUrl} />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
  }
`
